<script lang="ts" setup>
import { __ } from '@@/bits/intl'
import OzIconButton, { OzIconButtonColorScheme } from '@@/library/v4/components/OzIconButton.vue'
import { withDefaults } from 'vue'

withDefaults(
  defineProps<{
    /** Dark mode. */
    darkMode?: boolean | 'auto'
    disabled?: boolean

    /**
     * Automatically uses an `<a>` tag if href is present,
     * `<button>` otherwise.
     */
    href?: string

    /** Button label. */
    label?: string

    /** See `OzContainedButton`. */
    colorScheme?: OzIconButtonColorScheme

    /**
     * Override the preset icon size.
     * See `OzIconButton`.
     */
    iconSize?: number
  }>(),
  {
    darkMode: 'auto',
    disabled: false,
    href: undefined,
    label: __('Go back'),
    colorScheme: OzIconButtonColorScheme.Secondary,
    iconSize: undefined,
  },
)

defineEmits(['click'])
</script>

<script lang="ts">
export { OzIconButtonColorScheme as OzBackButtonColorScheme } from '@@/library/v4/components/OzIconButton.vue'
export default {}
</script>

<template>
  <OzIconButton
    :dark-mode="darkMode"
    :label="label"
    :href="href"
    :icon-size="iconSize"
    :color-scheme="colorScheme"
    :disabled="disabled"
    icon-name="chevron_left_center"
    @click="$emit('click', $event)"
  />
</template>
